<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "App",
  data() {
    return {
      colores: "",
    };
  },

  async created() {
    await this.getAllInfoClr("profile");

    this.colores = this.profiles;
    this.actualizarColores();
  },
  watch: {
    profiles() {
      this.actualizarColores();
    },
  },
  computed: {
    profiles() {
      return this.$store.getters["profile/data"];
    },
  },
  methods: {
    ...mapActions("colors", ["getAllInfoClr"]),
    ...mapActions("profile", ["getAllInfoPrf"]),
    actualizarColores() {
      const arr = this.$store.getters["colors/data"];

      if (Array.isArray(arr)) {
        for (var i = 0; i < arr.length; i++) {
          if (i == 0) {
            document.documentElement.style.setProperty(
              "--color-1",
              arr[i].code
            );
          } else if (i == 1) {
            document.documentElement.style.setProperty(
              "--color-2",
              arr[i].code
            );
          } else if (i == 2) {
            document.documentElement.style.setProperty(
              "--color-3",
              arr[i].code
            );
          } else if (i == 3) {
            document.documentElement.style.setProperty(
              "--color-4",
              arr[i].code
            );
          } else if (i == 4) {
            document.documentElement.style.setProperty(
              "--color-5",
              arr[i].code
            );
          } else if (i == 5) {
            document.documentElement.style.setProperty(
              "--color-6",
              arr[i].code
            );
          } else if (i == 6) {
            document.documentElement.style.setProperty(
              "--color-7",
              arr[i].code
            );
          }
        }
      }
    },
    scroll(event) {
      let number = event.target.documentElement.scrollTop;
      let offset = number * 1;
      localStorage.setItem("scroll", offset);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.scroll);
  },
};
</script>



<style>
@import "./css/default.css";

@import url("https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&family=Open+Sans:wght@300;400;500;600;700;800&family=Sora:wght@100;200;300;400;500;600;700;800&family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

html {
  scroll-behavior: smooth;
}

:root {
  /** No eliminar los 7 colores */
  --color-1: #e21f1d;
  --color-2: #242424;
  --color-3: #a7a7a7;
  --color-4: #f5f5f5;
  --color-5: #ffffff;
  --color-6: #d9d9d9;
  --color-7: #ffffff;
  --color-9: #ea9f2f;
  --color-8: #212121;

  --Sans: "Source Sans Pro", sans-serif;
  --Open_Sans: "Open Sans", sans-serif;
  --Sora: "Sora", sans-serif;
  --Mukta: "Mukta", sans-serif;
}

input#select-ch, input#select-ch1,input#select-ch2,input#select-ch3,input#select-ch4,input#select-ch5,input#select-ch6,input#select-ch7 {
  display: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0vw;
}

body {
  margin: 0vw;
}

html {
  scroll-behavior: smooth;
}

input:focus-visible {
  outline: none !important;
}

input,
textarea,
select {
  padding-left: 0.5vw;
  outline: none;
}

/* INICIO CSS GACETA */

.B4Carou .VueCarousel-inner {
  max-height: 243vw !important;
}

.B7 .VueCarousel-slide {
  flex-basis: auto;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  width: 75vw;
}
.B7 .VueCarousel-navigation-button {
  position: initial;
  /*margin-left: 36%;*/
}
.B7 p.NButton {
  font-size: 12vw;
  color: var(--color-1);
  font-weight: 700;
}

@media (min-width: 768px) {
  .B4Carou .VueCarousel-inner {
    height: 104vw !important;
  }
  .B7 .VueCarousel-slide {
    flex-basis: inherit;
    display: block;
    flex-flow: row;
    justify-content: space-between;
    width: auto;
  }
  .B7 .VueCarousel-navigation-button {
    position: absolute !important;
    /*margin-left: 0% !important;*/
  }
  .B7 p.NButton {
    font-size: 2vw;
    color: var(--color-1);
    font-weight: 700;
  }
}

/* FIN CSS GACETA */

.dflx {
  display: flex;
}

.pointer {
  cursor: pointer;
}

.jcsb {
  justify-content: space-between;
}
.cart_success {
  color: white !important;
  background-color: green !important;
}
.cart_error {
  color: white !important;
  background-color: red !important;
}

.frame-g-c .VueCarousel-navigation-button,
.modal-gal .VueCarousel-navigation-button {
  color: transparent !important;
  background-size: 100%;
  height: 11.556vw;
  padding: 0 !important;
  background-repeat: no-repeat;
}
.frame-g-c .VueCarousel-navigation-prev,
.modal-gal .VueCarousel-navigation-prev {
  background-image: url("assets/btnleft.svg");
}
.frame-g-c .VueCarousel-navigation-next,
.modal-gal .VueCarousel-navigation-next {
  background-image: url("assets/btnright.svg");
}

@media (max-width: 768px) {
  .frame-g-c .VueCarousel-navigation {
    width: 20vw;
    display: flex;
    justify-content: space-between;
    /*
    margin: 10.386vw auto 14.976vw; */
    margin: 10.386vw auto 0;
  }
  .modal-gal .VueCarousel-navigation {
    width: 39.13vw;
    display: flex;
    justify-content: space-between;
    margin: 10.386vw auto 14.976vw;
  }
  .frame-g-c .VueCarousel-navigation-button,
  .modal-gal .VueCarousel-navigation-button {
    width: 5.556vw;
    position: relative !important;
    top: auto !important;
    transform: none !important;
  }
  .gaceta .VueCarousel-dot {
    padding: 3vw !important;
    width: 9vw !important;
    height: 9vw !important;
    margin-bottom: 19.565vw !important;
  }
  .gaceta .VueCarousel-dot--active {
    background-color: #e21f1d !important;
  }
  .cart_msg {
    font-size: 3.9vw;
    font-family: "Sora";
  }
}
@media (min-width: 768px) {
  .cart_msg {
    font-size: 0.69vw;
    font-family: "Sora";
    width: fit-content;
    padding: 0.3vw;
    border-radius: 2vw;
  }
  .frame-g-c .VueCarousel-navigation-button,
  .modal-gal .VueCarousel-navigation-button {
    background-size: 100%;
    width: 0.677vw;
    height: 1.302vw;
  }
  .frame-g-c .VueCarousel-navigation-prev,
  .modal-gal .VueCarousel-navigation-prev {
    background-image: url("assets/btnleft.svg");
    left: -2.656vw !important;
  }
  .frame-g-c .VueCarousel-navigation-next,
  .modal-gal .VueCarousel-navigation-next {
    right: -2.656vw !important;
    background-image: url("assets/btnright.svg");
  }
}
</style>
