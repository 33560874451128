import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import colors from './modules/colors'
import logo from './modules/logo'
import main from './modules/main'
import profile from './modules/profile'
import usuarios from './modules/usuarios'
import blog from './modules/blog'
import tag from './modules/tag'
import colaboradores from './modules/colaboradores';
import blog_category from './modules/blog_category'
import category from './modules/category'
import vacantes from './modules/vacantes'
import clientes from './modules/clientes'
import galserv from './modules/galserv'
import galresp from './modules/galresp'
import galdist from './modules/galdist'
import suscripciones from './modules/suscripciones'
import navi from './modules/navi'
import sectionbk from './modules/sectionbk'
import logro from './modules/logro'
import directorio from './modules/directorio'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main: main,
    usuarios: usuarios,
    profile:profile,
    colors:colors,
    logo:logo,
    blog:blog,
    tag:tag,
    blog_category:blog_category,
    colaboradores: colaboradores,
    category:category,
    colors:colors,
    vacantes:vacantes,
    clientes:clientes,
    suscripciones: suscripciones,
    galserv:galserv,
    galresp:galresp,
    galdist:galdist,
    navi:navi,
    sectionbk:sectionbk,
    logro:logro,
    directorio:directorio
  }
})
